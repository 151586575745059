import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import styles from "./Billpage.module.scss";
import { Avatar, Box, Button } from "@mui/material";
import SaltshakerLogo from "../../Images/SaltShaker-Logo-Transparent 1.png";
import CalendarIcon from "../../Images/Calendar.png";
import BotIcon from "../../Images/Boat.png";
import HomeIcon from "../../Images/Home.png";
import Line from "../../Images/Line 1.png";
import EditIcon from "../../Images/Edit.png";
import AmountoffIcon from "../../Images/Amountoff.png";
import ClockIcon from "../../Images/Clock.png";

const Billpage = () => {
  const navigate = useNavigate(); // Create navigate function
  const handleBookNow = () => {
    navigate("/chekout"); // Navigate to Checkout page
  };
  return (
    <div className={styles.billpage_maincontainer}>
      <Box component="section" sx={{ p: 2 }} className={styles.bill_box}>
        <div className={styles.billbox_headlogo}>
          <img
            src={SaltshakerLogo}
            alt={""}
            className={styles.saltshaker_logo}
          />
        </div>
        <div className={styles.billbox_headcontent}>
          <h2>Charter Quote</h2>
          <small>Valid until Jan 25,24</small>
        </div>

        <div className={styles.billbox_datetimedetails}>
          <div className={styles.billbox_datetimecontent}>
            <Avatar
              alt="CalendarIcon"
              src={CalendarIcon}
              sx={{ width: 15, height: 15 }}
            />
            <span>8am - 4pm Jun 26,2024</span>
          </div>

          <div className={styles.billbox_datetimecontent}>
            <Avatar
              alt="BotIcon"
              src={BotIcon}
              sx={{ width: 15, height: 15 }}
            />
            <span>Boat: Salt Shaker</span>
          </div>

          <div className={styles.billbox_datetimecontent}>
            <Avatar
              alt="HomeIcon"
              src={HomeIcon}
              sx={{ width: 15, height: 15 }}
            />
            <span>SY Cat ELIYSIAN Bali 54'</span>
          </div>
        </div>

        <div className={styles.billbox_itemamountdetails}>
          <div className={styles.billbox_itemcontent}>
            <h4>Full Day (8hrs)Deep Sea Fishing:</h4>
            <span>$1450</span>
          </div>

          <div className={styles.billbox_itemcontent}>
            <h4>Passengers x5: </h4>
            <span>$25</span>
          </div>

          <div className={styles.billbox_itemcontent}>
            <h4>Fishing Licenses x3: </h4>
            <span>$180</span>
          </div>

          <div className={styles.billbox_itemcontent}>
            <h4>Card processing fee: </h4>
            <span>$207.37</span>
          </div>

          <div className={styles.billbox_itemcontent}>
            <h4>Extended range pickup fee: </h4>
            <span>$200</span>
          </div>

          <div className={styles.billbox_itemcontent}>
            <h4 className={styles.billbox_discountcontent}>Discount: </h4>
            <span className={styles.billbox_discountcontent}>$206.24</span>
          </div>
        </div>

        <div className={styles.billbox_linerow}>
          <img src={Line} alt="Line" className={styles.billbox_line} />
        </div>
        <div className={styles.billbox_editrow}>
          <Avatar
            alt="EditIcon"
            src={EditIcon}
            sx={{ width: 15, height: 15 }}
          />
          <strong>edit</strong>
        </div>

        <div className={styles.billbox_amountoff}>
          <img src={AmountoffIcon} alt="AmountoffIcon" />
          <h2>$1,856.13</h2>
        </div>

        <div className={styles.billbox_bottombtn}>
          <Button variant="contained" className={styles.billbox_booknowbtn} onClick={handleBookNow}>
            Book Now
          </Button>
          <div className={styles.billbox_bottomtimer}>
            <Avatar
              alt="ClockIcon"
              src={ClockIcon}
              sx={{ width: 15, height: 15 }}
            />
            <small>Takes 1 min</small>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Billpage;
