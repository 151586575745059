import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Form } from "react-router-dom";

const FRONTEND_URL = process.env.REACT_APP_REDIRECT_URL

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${FRONTEND_URL}/success`,
      },
    });

    if (error) {
      setMessage(error.message);
    } else {
      setMessage("Payment processing...");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <h4 className="payInfo" style={{ marginBottom: "20px" }}>
        Payment Information
      </h4>
      <div className="">
        <label
          className="labelFirstCard"
          style={{
            position: "relative",
            fontSize: "15px",
            float: "left",
            marginBottom: "3px",
            color: "gray",
          }}
        >
          Name on Card
        </label>
        <input
          style={{
            height: "40px",
            width: "100%",
            backgroundColor: "#ffffff",
            border: "1px solid #d8d3d3",
          }}
          type="text"
        />
      </div>
      <PaymentElement id="payment-element" />
      <label
        className="labelFirstCard"
        style={{
          position: "relative",
          fontSize: "15px",
          float: "left",
          marginBottom: "3px",
          color: "gray",
        }}
      >
        Cardholder phone number
      </label>
      <input
        style={{
          height: "40px",
          width: "100%",
          marginBottom: "10px",
          backgroundColor: "#ffffff",
          border: "1px solid #d8d3d3",
        }}
        type="text"
      />
      <label
        className="labelFirstCard"
        style={{
          position: "relative",
          fontSize: "15px",
          float: "left",
          marginBottom: "3px",
          color: "gray",
        }}
      >
        Cardholder email
      </label>
      <input
        style={{
          height: "40px",
          width: "100%",
          backgroundColor: "#ffffff",
          border: "1px solid #d8d3d3",
          marginBottom: "30px",
        }}
        placeholder="john@deo.com"
        type="text"
      />
      <input type="checkbox" />
      <label style={{ paddingLeft: "15px" }}>
        i accept the{" "}
        <span>
          <a href=""> terms of services</a>
        </span>
      </label>
      <button style={{marginTop:"15px"}} disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner"></div> : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
