import React from "react";
import Successimg from '../../Images/success.png'

export default function Success() {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div style={{ marginBottom: "20px" }}>
        <img
          src={Successimg}
          alt="Success"
          style={{ width: "100px", height: "100px" }}
        />
      </div>
      <h2 style={{ fontSize: "24px", fontWeight: "bold", margin: "10px 0" }}>Payment Successful</h2>
      <p style={{ fontSize: "16px", color: "gray" }}>bb2pffvxmm3lz9f6p9tzzbb2pff6enys2</p>
      <h3 style={{ fontSize: "20px", margin: "20px 0" }}>Thank you!</h3>
      <p style={{ fontSize: "16px", textAlign: "center", margin: "10px 0", width:'80%' }}>We’re almost done. Check your email to complete booking.</p>
      <p style={{ fontSize: "16px", color: "gray", margin: "20px 0" }}>john@doe.com</p>
      <button style={{ padding: "10px 20px", fontSize: "16px", color: "#fff", backgroundColor: "#000", border: "none", cursor: "pointer", borderRadius:'10px', width:'40%' ,height:'3rem' }} onClick={() => window.location = "mailto:john@doe.com"}>Check Email</button>
    </div>
  );
}
