// import logo from './logo.svg';
// import './App.css';
// import { Route, Routes } from 'react-router-dom';
// import Billpage from './Components/Billing/Billpage';
// import CheckoutForm from './Components/Checkout/CheckoutForm';
// import { useEffect, useState } from 'react';
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements, PaymentElement } from "@stripe/react-stripe-js";

// const stripePromise = loadStripe("pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3");

// function App() {

//   const [clientSecret, setClientSecret] = useState("");

//   useEffect(() => {
//     // Create PaymentIntent as soon as the page loads
//       fetch("http://localhost:4242/create-payment-intent", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
//       })
//         .then((res) => res.json())
//         .then((data) => setClientSecret(data.clientSecret));
//   }, []);

//   const appearance = {
//     theme: 'stripe',
//   };
//   const options = {
//     clientSecret,
//     appearance,
//   };

//   return (
//     <>
//      <Routes>
//         <Route path='/' element={<Billpage />} />
//         {/* <Route path='/chekout' element={<h1>test</h1>} /> */}
//         <Route path='/chekout' element={<CheckoutForm /> } />
//      </Routes>

// {/* <div className="App">
//       {clientSecret && (
//         // <Elements options={options} stripe={stripePromise}>
//         <Elements stripe={stripePromise}>
//           <CheckoutForm />
//         </Elements>
//       )}
//     </div> */}

//     </>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Billpage from "./Components/Billing/Billpage";
import CheckoutForm from "./Components/Checkout/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SuccessPage from "./Components/Success/Success";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);

function App() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(`${BACKEND_URL}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Billpage />} />
      <Route
          path="/chekout"
          element={
            clientSecret ? (
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            ) : (
              <div>Loading...</div>
            )
          }
        />
        <Route path="/success" element={<SuccessPage/>}></Route>
      </Routes>
      
    </div>
  );
}

export default App;
